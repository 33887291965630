@tailwind base;
@tailwind components;
@tailwind utilities;

.content-post {
  max-width: 670px;
}

.content-post * {
  box-sizing: border-box;
}

.content-post p {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: 'DM Sans', sans-serif;
}

.content-post h5 {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 24px;
}

.content-post pre {
  width: 100%;
  border-radius: 5px;
}

.content-post a {
  color: #D81E5B;
}

.content-post ul {
  list-style: disc;
  padding-left: 10px;
}